<template>
    <DelayHydration>
        <section
            v-if="pending || images?.length || $slots.description"
            class="storytelling-block"
            v-bind="$attrs"
            :style="{
                '--background-color': backgroundColor,
                '--text-color': textColor
            }"
        >
            <div
                ref="content"
                class="storytelling-block__content"
            >
                <template v-if="pending">
                    <Skeletor
                        v-for="i in [1,2,3,4]"
                        :key="i"
                        height="40vw"
                        :shimmer="false"
                        class="storytelling-block__image"
                    />
                </template>
                <BaseImage
                    v-for="(image, index) in images"
                    v-else
                    ref="imageElements"
                    :key="index"
                    class="storytelling-block__image"
                    :image="image"
                    sizes="(min-width: 1440px) 418px, (min-width: 768px) 226px, 104px"
                />

                <article
                    ref="text"
                    class="storytelling-block__text"
                >
                    <span
                        v-if="!!$slots.title || pending"
                        class="storytelling-block__label"
                    >
                        <template v-if="pending">
                            <Skeletor />
                        </template>
                        <slot
                            v-else
                            name="title"
                        />
                    </span>

                    <p
                        v-if="!!$slots.description || pending"
                        class="storytelling-block__description"
                    >
                        <template v-if="pending">
                            <Skeletor />
                            <Skeletor />
                            <Skeletor />
                            <Skeletor />
                        </template>
                        <slot
                            v-else
                            name="description"
                        />
                    </p>

                    <nav
                        v-if="!!$slots.link || pending"
                        class="storytelling-block__link"
                    >
                        <template v-if="pending">
                            <Skeletor width="6rem" />
                        </template>
                        <slot
                            v-else
                            name="link"
                        />
                    </nav>
                </article>
            </div>
        </section>
    </DelayHydration>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';
import { useScrollAnimations } from '~/composables/useScrollAnimations';


const { resolveColor, getTextColor } = useColorPalette();

interface Props {
    backgroundColor?: keyof ColorPalette;
    images?: {[key: string]: String | Number}[];
    pending?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'pink',
    images: () => [],
    pending: () => false
});

const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor));

const imageElements = ref([]);
const text = ref();
const content = ref();

onMounted(() => {
    const { parallax } = useScrollAnimations();

    parallax(text.value, { offset: .9, trigger: content.value });
    parallax(imageElements.value?.[0], { offset: .7, trigger: content.value });
    parallax(imageElements.value?.[1], { offset: .8, trigger: content.value });
    parallax(imageElements.value?.[2], { offset: .9, trigger: content.value });
    parallax(imageElements.value?.[3], { offset: .7, trigger: content.value });
});
</script>

<style lang="less">
.storytelling-block {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color var(--transition-medium);
    overflow: hidden;
}

.storytelling-block__content {
    .base-grid(var(--grid-maxWidth-page), false);
    grid-template-rows: auto 1fr auto;
    row-gap: var(--spacing-md);
    height: auto;

    @media @q-md-min {
        grid-template-rows: auto auto 1fr;
        row-gap: var(--spacing-2xl);
    }
}

.storytelling-block__image {
    grid-row: 1;

    &:nth-child(1) {
        grid-column: left-side-gutter/left-gutter;
    }

    &:nth-child(2) {
        grid-column: second;
        grid-row: 1/2;

        @media @q-md-min {
            grid-row: 1/3;
        }
    }

    &:nth-child(3) {
        grid-column: third/-1;
        grid-row: 1/2;

        @media @q-md-min {
            grid-row: 1/3;
        }
    }

    &:nth-child(4) {
        grid-column: left-side-gutter/left-gutter;
        grid-row: 3/4;

        @media @q-md-min {
            grid-row: 2/4;
        }
    }

    .base-image__image {
        object-fit: contain;
        object-position: top;
    }
}

.storytelling-block__text {
    grid-row: 2/3;
    grid-column: 2/7;

    display: flex;
    flex-direction: column;
    gap: var(--spacing-2xs);

    @media @q-md-min {
        grid-row: 3/4;
        grid-column: second/right-side-gutter;
        margin-bottom: calc(var(--spacing-2xl) * 2);

        gap: var(--spacing-sm);
    }
}

.storytelling-block__label {
    .typography-label();
}

.storytelling-block__description {
    .typography-h1();
    width: 100%;
}

.storytelling-block__link {
    flex: 1 1 auto;
    align-self: flex-end;

    .base-arrow-link {
        .typography-label();
    }
}
</style>
